import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "./registerServiceWorker";

Vue.config.productionTip = false;

store.commit("initializeStore");

router.beforeEach((to, _, next) => {
  const isLoggedIn = router.app.$store.getters.isLoggedIn;

  if (to.name !== "login" && !isLoggedIn) {
    next({ name: "login" });
  } else {
    next();
  }
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
