import store from "../store";

const apiBase = process.env.VUE_APP_API_BASE_URL;

const apiFetch = (url, method = "GET", data = null) => {
  const body = data ? JSON.stringify(data) : undefined;
  const headers = { "Content-Type": "application/json" };

  if (store.getters.isLoggedIn) {
    headers["X-CSRF-Token"] = store.getters.userToken;
  }

  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers,
      body,
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(resolve);
        } else {
          handleAPIError(response).then(() => {
            reject();
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const login = (data) => {
  return apiFetch(`${apiBase}/sessions`, "POST", data).catch(() => {
    store.dispatch("setError", "Identifiants incorrects, réessaye !");
  });
};

const refresh = () => {
  return apiFetch(`${apiBase}/sessions`, "PUT");
};

const logout = () => {
  return apiFetch(`${apiBase}/sessions`, "DELETE");
};

const createPost = (post) => {
  return apiFetch(`${apiBase}/posts`, "POST", post).catch(() => {
    store.dispatch("setError", "Erreur à la création du message.");
  });
};

const getPosts = () => {
  return apiFetch(`${apiBase}/posts`);
};

const getLivePost = () => {
  return apiFetch(`${apiBase}/machines/current`).then(
    (machine) => machine.live_post
  );
};

const handleAPIError = async (response) => {
  const responseStatus = response.status;
  const responseBody = await response.json();
  const responseError = responseBody.error;

  console.log("responseError", responseBody, responseError);

  if (responseStatus === 401) {
    if (responseError === "Expired") {
      //   console.log("isExpired");
      //   return store.dispatch("refreshSession");
      store.dispatch(
        "setError",
        "Ta session a expiré, actualise la page pour te reconnecter."
      );
    }

    return store.dispatch("forgetSession");
  }
};

export { login, logout, createPost, getPosts, refresh, getLivePost };
