<template>
  <v-sheet v-if="loading">
    <v-skeleton-loader
      type="card-heading, image, paragraph, actions"
      class="mb-6"
      elevation="2"
    />
  </v-sheet>

  <v-sheet v-else>
    <post
      v-for="post in posts"
      :key="post.title"
      :user-name="post.user.name"
      :user-image="post.user.image"
      :content="post.content"
      :asset-url="post.asset_url"
      :is-live="post.live"
      :next-display-date="post.next_display_at"
      class="my-6"
    />
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Post from "./Post.vue";

export default {
  name: "Feed",

  components: {
    Post,
  },

  props: {
    liveIntervalInMinutes: {
      type: Number,
      default: 1,
    },
  },

  data: () => ({
    loading: true,
    liveTimer: null,
  }),

  computed: {
    ...mapGetters(["posts"]),
  },

  created() {
    this.fetchPosts().then(() => {
      this.loading = false;
    });
  },

  mounted() {
    this.liveTimer = setInterval(() => {
      this.fetchLivePost();
    }, this.liveIntervalInMinutes * 60 * 1000);
  },

  beforeDestroy() {
    clearInterval(this.liveTimer);
  },

  methods: {
    ...mapActions(["fetchPosts", "fetchLivePost"]),
  },
};
</script>
