<template>
  <v-container>
    <editor />
    <feed />
  </v-container>
</template>

<script>
// @ is an alias to /src
import Feed from "@/components/Feed/Feed.vue";
import Editor from "@/components/Editor/Editor.vue";

export default {
  name: "Home",
  components: {
    Feed,
    Editor,
  },
};
</script>
