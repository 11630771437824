import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 800,
    },
  },
  theme: {
    themes: {
      light: {
        primary: "#795548",
        secondary: "#ffc107",
        accent: "#cddc39",
        error: "#e91e63",
        warning: "#ff9800",
        info: "#00bcd4",
        success: "#8bc34a",
      },
    },
  },
});
