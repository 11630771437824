import Vue from "vue";
import Vuex from "vuex";

import { formatRelative } from "date-fns";
import { fr } from "date-fns/locale";

import {
  createPost,
  getPosts,
  login,
  logout,
  refresh,
  getLivePost,
} from "@/services/api";
import { SessionStorageKeys } from "@/constants/auth";
import { MAX_ALERT_TIME } from "@/constants/config";

Vue.use(Vuex);

const initialAlert = () => ({
  isError: false,
  message: null,
});

const initialState = () => ({
  user: {
    token: null,
  },
  posts: [],

  alert: initialAlert(),
});

export default new Vuex.Store({
  state: initialState(),

  getters: {
    isLoggedIn(state) {
      return state.user && state.user.token;
    },

    posts(state) {
      return state.posts;
    },

    userToken(state) {
      return state.user.token;
    },

    hasAlert(state) {
      return !!state.alert.message;
    },

    getAlert(state) {
      return state.alert;
    },
  },

  actions: {
    login({ commit }, { email, password }) {
      return login({ email, password }).then((response) => {
        commit("login", response.csrf);
      });
    },

    logout({ dispatch }) {
      return logout().then(() => {
        dispatch("forgetSession");
      });
    },

    forgetSession({ dispatch }) {
      sessionStorage.removeItem(SessionStorageKeys.CSRF_TOKEN);

      return dispatch("resetState");
    },

    refreshSession({ commit }) {
      return refresh().then((response) => {
        commit("login", response.csrf);
      });
    },

    fetchPosts({ commit, dispatch }) {
      return getPosts().then((posts) => {
        commit("setPosts", posts);
        dispatch("fetchLivePost");
      });
    },

    createPost({ commit, dispatch }, userPost) {
      return createPost(userPost).then((post) => {
        commit("createPost", post);
        dispatch("fetchLivePost");
      });
    },

    fetchLivePost({ commit }) {
      return getLivePost().then((post) => {
        commit("setLivePost", post.id);
      });
    },

    resetState({ commit }) {
      commit("resetState");
    },

    setError({ commit, dispatch }, message) {
      commit("setAlert", { error: true, message });
      setTimeout(() => {
        dispatch("clearAlert");
      }, MAX_ALERT_TIME);
    },

    setSuccess({ commit, dispatch }, message) {
      commit("setAlert", { error: false, message });
      setTimeout(() => {
        dispatch("clearAlert");
      }, MAX_ALERT_TIME);
    },

    clearAlert({ commit }) {
      commit("clearAlert");
    },
  },

  mutations: {
    initializeStore(state) {
      const sessionStorageUser = sessionStorage.getItem(
        SessionStorageKeys.CSRF_TOKEN
      );

      if (sessionStorageUser) {
        state.user = { ...state.user, token: sessionStorageUser };
      }
    },

    login(state, csrfToken) {
      state.user.token = csrfToken;
      sessionStorage.setItem(SessionStorageKeys.CSRF_TOKEN, csrfToken);
    },

    resetState(state) {
      Object.assign(state, initialState());
    },

    setPosts(state, posts) {
      state.posts = posts;
    },

    setLivePost(state, postID) {
      state.posts = state.posts.map((post) => ({
        ...post,
        live: post.id === postID,
      }));
    },

    createPost(state, post) {
      state.posts = [post, ...state.posts.slice(0, -1)];
    },

    setAlert(state, { error, message }) {
      state.alert.isError = error;
      state.alert.message = message;
    },

    clearAlert(state) {
      Object.assign(state.alert, initialAlert());
    },
  },
});

// eslint-disable-next-line no-unused-vars
const formatPost = (post, relativeDate = new Date()) => {
  return {
    ...post,
    next_display_at: formatRelative(
      new Date(post.next_display_at),
      relativeDate,
      { locale: fr, weekStartsOn: 1 }
    ),
  };
};
