<template>
  <v-card class="mb-6">
    <v-form
      v-model="valid"
      @submit.prevent="submit"
    >
      <v-card-title>Envoyer un message</v-card-title>
      <v-card-text>
        <v-textarea
          v-if="activeType === 'text'"
          v-model="content"
          label="Ton message"
          auto-grow
          :counter="maxPostSize"
          min-length="10"
          :max-length="maxPostSize"
          :rules="[(v) => v.length > 10 && v.length <= maxPostSize]"
        />

        <v-file-input
          v-if="activeType === 'image'"
          label="Ton image"
          outlined
          dense
        />
      </v-card-text>

      <v-card-actions>
        <div v-if="hasMultipleTypes">
          <v-btn
            v-if="isSupportedType('text')"
            icon
            @click="activeType = 'text'"
          >
            <v-icon>mdi-format-text-variant-outline</v-icon>
          </v-btn>

          <v-btn
            v-if="isSupportedType('image')"
            icon
            @click="activeType = 'image'"
          >
            <v-icon>mdi-file-image-outline</v-icon>
          </v-btn>
        </div>

        <v-spacer />

        <v-btn
          color="secondary"
          type="submit"
          :disabled="!valid"
          :loading="loading"
        >
          Valider
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Editor",

  data() {
    return {
      activeType: "text",
      supportedTypes: ["text"],

      content: "",
      valid: true,
      loading: false,

      maxPostSize: 150,
    };
  },

  computed: {
    hasMultipleTypes() {
      return this.supportedTypes.length > 1;
    },
  },

  methods: {
    ...mapActions(["createPost", "setSuccess"]),

    submit() {
      this.loading = true;
      this.createPost({ content: this.content }).then(() => {
        this.setSuccess("Message envoyé !").then(() => {
          this.content = "";
          this.valid = true;
          this.loading = false;
        });
      });
    },

    isSupportedType(type) {
      return this.supportedTypes.includes(type);
    },
  },
};
</script>

<style></style>
