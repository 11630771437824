<template>
  <v-card elevation="2">
    <div class="py-2">
      <v-img :src="assetUrl" />
    </div>

    <v-card-subtitle v-if="isLive">
      <v-icon color="red">
        mdi-record
      </v-icon>
      Affichage en cours
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: "Post",

  props: {
    userName: {
      type: String,
      required: true,
    },
    userImage: {
      type: String,
      default: "https://avatars0.githubusercontent.com/u/9064066?v=4&s=460",
    },
    content: {
      type: String,
      required: true,
    },
    assetUrl: {
      type: String,
      required: true,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    nextDisplayDate: {
      type: String,
      default: null,
    },
  },
};
</script>
