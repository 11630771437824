<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <v-toolbar-title>Saraswati</v-toolbar-title>

      <v-spacer />

      <v-btn
        v-if="isLoggedIn"
        icon
        @click="logoutAndRedirect"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container max-width="800px">
        <router-view />

        <v-snackbar
          :value="hasAlert"
          timeout="-1"
          :color="getAlert.isError ? 'red' : 'green'"
        >
          {{ getAlert.message }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="clearAlert"
            >
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  data: () => ({}),

  computed: {
    ...mapGetters(["isLoggedIn", "hasAlert", "getAlert"]),
  },

  methods: {
    ...mapActions(["logout", "clearAlert"]),

    logoutAndRedirect() {
      this.logout().then(() => {
        this.$router.push("login");
      });
    },
  },
};
</script>
