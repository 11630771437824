<template>
  <v-card
    white
    class="pa-4"
  >
    <v-form
      v-model="valid"
      @submit.prevent="handleSubmit"
    >
      <v-text-field
        v-model="email"
        label="Email"
        type="email"
        :rules="rules.required"
        required
      />

      <v-text-field
        v-model="password"
        label="Password"
        type="password"
        :rules="rules.required"
        required
      />

      <v-btn
        color="success"
        :disabled="!valid"
        type="submit"
      >
        Se connecter
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",

  data() {
    return {
      valid: true,

      email: null,
      password: null,

      rules: {
        required: [(value) => !!value || "Ce champ est requis"],
      },
    };
  },

  methods: {
    ...mapActions(["login", "setSuccess"]),

    handleSubmit() {
      this.login({ email: this.email, password: this.password })
        .then(() => {
          this.$router.push("/").then(() => {
            this.setSuccess("Bienvenue !");
          });
        })
        .catch(console.error);
    },
  },
};
</script>
